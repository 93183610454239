<template>
  <arora-button
    ignore-settings
    :class-name="className"
    :disabled="disabled"
    :label="label"
    @click="async () => await accept()"
  />
  <transition
    v-if="showErrorMessage"
    mode="out-in"
    name="fade"
  >
    <div
      v-if="promoData && promoData.PromoCode === code && promoData.ErrorMessages.length > 0"
      class="v-d-flex v-flex-column v-promo-error-message"
    >
      <span
        v-for="(message, index) in promoData.ErrorMessages"
        :key="`promo-error-message-${index}`"
        class="v-error-color"
        v-html="message"
      />
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { Promo } from '~types/clientStore'

const {
  code,
  onApplyCallback = () => {
    return
  },
  showErrorMessage = false
} = defineProps<{
  code: string
  label: string
  className: string
  onApplyCallback?: () => void
  showErrorMessage?: boolean
}>()

const clientStore = useClientStore()

const disabled = ref<boolean>(false)

const promoData = computed<Promo | undefined>(() => {
  return clientStore.ClientState?.data?.Promo
})

async function accept(): Promise<void> {
  disabled.value = true
  await clientStore.applyPromoCode(decodeURIComponent(code), showErrorMessage)

  if (promoData.value && promoData.value.IsPromoApplied && promoData.value.ErrorMessages.length === 0) {
    onApplyCallback()
  }
}
</script>
